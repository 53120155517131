import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import "./privacy.css";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

function Privacy() {
  const [tPrivacy] = useTranslation("privacy");
  const [tSMetaData] = useTranslation("metaData");
  const navigate = useNavigate();
  const location = useLocation();
  const { us, es } = location.state || "";

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].privacy}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].privacy}`
  );

  const handleGoBack = () => {
    if (
      !sessionStorage.getItem("routeEn") ||
      !sessionStorage.getItem("routeEs") ||
      !localStorage.getItem("lng") ||
      !us ||
      !es
    ) {
      navigate("/");
    } else {
      if (localStorage.getItem("lng") === "us") {
        navigate(us);
      } else {
        navigate(es);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{tSMetaData("avisoPrivacidad.title")}</title>
        <meta
          name="description"
          content={tSMetaData("avisoPrivacidad.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <section className="privacy_box">
        <div className="row">
          <div className="title_privacy">
            <div className="col">
              <div className="privacy-intro">
                <button onClick={handleGoBack}>
                  <FaArrowLeft />
                </button>
                <h1 className="aviso_priv">
                  {tPrivacy("privacidad.title_privacy")}
                </h1>
              </div>
              <div className="box_date">
                <p className="privacy_date">{tPrivacy("privacidad.update")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="texto_box">
              <div className="container conta_privacy">
                <p className="ley">
                  <strong>{tPrivacy("texto_privacy.ley")}</strong>
                </p>

                <h3>{tPrivacy("texto_privacy.uno")}</h3>
                <p>
                  <strong>{tPrivacy("texto_privacy.pa_uno")}</strong>{" "}
                  {tPrivacy("texto_privacy.pa_uno_uno")}
                  <strong>{tPrivacy("texto_privacy.cargo")}</strong>
                  {tPrivacy("texto_privacy.p")}
                </p>

                <h3>{tPrivacy("texto_privacy.dos")}</h3>
                <p>{tPrivacy("texto_privacy.pa_dos")}</p>
                <ul>
                  <li>
                    <p>{tPrivacy("texto_privacy.atender")}</p>
                  </li>
                  <li>
                    <p>{tPrivacy("texto_privacy.datos")}</p>
                  </li>
                </ul>
                <p>
                  {tPrivacy("texto_privacy.todo")}{" "}
                  <a href="http://">cadatospersonales@aeromexico.com.</a>
                </p>
                <h3>{tPrivacy("texto_privacy.tres")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres")}</p>

                <h3>{tPrivacy("texto_privacy.tresuno")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres_uno")}</p>

                <h3>{tPrivacy("texto_privacy.tresdos")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres_dos")}</p>

                <h3>{tPrivacy("texto_privacy.cuatro")}</h3>
                <p>{tPrivacy("texto_privacy.pa_cuatro")}</p>
                <p>
                  <strong>{tPrivacy("texto_privacy.inc_a")}</strong>{" "}
                  {tPrivacy("texto_privacy.parr_inc_a")}
                </p>
                <ul>
                  <li>{tPrivacy("texto_privacy.contactarlo")}</li>
                  <li>{tPrivacy("texto_privacy.info")}</li>
                  <li>{tPrivacy("texto_privacy.contrato")}</li>
                  <li>{tPrivacy("texto_privacy.especial")}</li>
                  <li>{tPrivacy("texto_privacy.registro")}</li>
                  <li>{tPrivacy("texto_privacy.reembolsos")}</li>
                  <li>{tPrivacy("texto_privacy.alguna")}</li>
                  <li>{tPrivacy("texto_privacy.aplicable")}</li>
                  <li>{tPrivacy("texto_privacy.elaboracion")}</li>
                  <li>{tPrivacy("texto_privacy.obligaciones")}</li>
                  <li>{tPrivacy("texto_privacy.envio")}</li>
                  <li>{tPrivacy("texto_privacy.seguridad")}</li>
                </ul>

                <p>
                  <strong>{tPrivacy("texto_privacy.inc_b")}</strong>{" "}
                  {tPrivacy("texto_privacy.parr_inc_b")}
                </p>
                <ul>
                  <li>{tPrivacy("texto_privacy.informarle")}</li>
                  <li>{tPrivacy("texto_privacy.publicidad")}</li>
                  <li>{tPrivacy("texto_privacy.comercial")}</li>
                  <li>{tPrivacy("texto_privacy.encuestas")}</li>
                </ul>

                <h3>{tPrivacy("texto_privacy.cuatrouno")}</h3>
                <p>{tPrivacy("texto_privacy.pa_cuatrouno")}</p>

                <h3>{tPrivacy("texto_privacy.cinco")}</h3>
                <p>{tPrivacy("texto_privacy.parr_cinco")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.servicio")}</li>
                  <li>{tPrivacy("texto_privacy.empresas")}</li>
                  <li>{tPrivacy("texto_privacy.aerea")}</li>
                  <li>{tPrivacy("texto_privacy.gha")}</li>
                  <li>{tPrivacy("texto_privacy.gsa")}</li>
                  <li>{tPrivacy("texto_privacy.paqueteria")}</li>
                  {/* <li>{tPrivacy("texto_privacy.autoridades")}</li> */}
                  <li>{tPrivacy("texto_privacy.judiciales")}</li>
                  <li>{tPrivacy("texto_privacy.sociedades")}</li>
                  <li>{tPrivacy("texto_privacy.medicos")}</li>
                  <li>{tPrivacy("texto_privacy.am")}</li>
                  <p>{tPrivacy("texto_privacy.realizar")}</p>
                  <li>{tPrivacy("texto_privacy.fusion")}</li>
                  <li>{tPrivacy("texto_privacy.legal")}</li>
                  <p>{tPrivacy("texto_privacy.lfpd")}</p>
                </ul>

                <h3>{tPrivacy("texto_privacy.seis")}</h3>
                <p>{tPrivacy("texto_privacy.pa_seis")}</p>
                <p>
                  {tPrivacy("texto_privacy.arco")} (
                  <a href="http://">cadatadospersonales@aeromexico.com</a>)
                  {tPrivacy("texto_privacy.arco1")}
                </p>

                <h3>{tPrivacy("texto_privacy.siete")}</h3>
                <p>{tPrivacy("texto_privacy.pa_siete")}</p>
                <p>{tPrivacy("texto_privacy.revocar")}</p>

                <h3>{tPrivacy("texto_privacy.ocho")}</h3>
                <p>{tPrivacy("texto_privacy.pa_ocho")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.finalidades")}</li>
                  <li>{tPrivacy("texto_privacy.enviar")}</li>
                  <li>
                    {tPrivacy("texto_privacy.evitar")}{" "}
                    <a href="http://www.rpc.profeco.gob.mx/">
                      www.rpc.profeco.gob.mx
                    </a>
                  </li>
                </ul>

                <h3>{tPrivacy("texto_privacy.nueve")}</h3>
                <p>
                  {tPrivacy("texto_privacy.pa_nueve")}{" "}
                  <a href="https://amcargo.aeromexico.com/">
                    amcargo.aeromexico.com.
                  </a>{" "}
                  {tPrivacy("texto_privacy.cookies")}
                </p>
                <p>{tPrivacy("texto_privacy.datos0")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.tipo")}</li>
                  <li>{tPrivacy("texto_privacy.horario")}</li>
                  <li>{tPrivacy("texto_privacy.secciones")}</li>
                  <li>
                    {tPrivacy("texto_privacy.previo")}{" "}
                    <a href="https://amcargo.aeromexico.com/">
                      amcargo.aeromexico.com.
                    </a>
                  </li>
                  <li>
                    {tPrivacy("texto_privacy.posterior")}{" "}
                    <a href="https://amcargo.aeromexico.com/">
                      amcargo.aeromexico.com.
                    </a>
                  </li>
                  <li>{tPrivacy("texto_privacy.ip")}</li>
                </ul>

                <h3>{tPrivacy("texto_privacy.finalidades1")}</h3>
                <ul>
                  <li>{tPrivacy("texto_privacy.opciones")}</li>
                  <li>{tPrivacy("texto_privacy.web")}</li>
                </ul>

                <p>
                  <strong>{tPrivacy("texto_privacy.explorer")}</strong>
                  <br />
                  <a href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">
                    http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.firefox")}</strong>
                  <br />
                  <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.chrome")}</strong>
                  <br />
                  <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">
                    http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.opera")}</strong>
                  <br />
                  <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                    http://help.opera.com/Windows/11.50/es-ES/cookies.html
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.safari")}</strong>
                  <br />
                  <a href="http://www.apple.com/legal/privacy/es/cookies/">
                    http://www.apple.com/legal/privacy/es/cookies/
                  </a>
                </p>

                <h3>{tPrivacy("texto_privacy.diez")}</h3>
                <p>{tPrivacy("texto_privacy.efectuar")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.anuncios")}</li>
                  <li>{tPrivacy("texto_privacy.internet")}</li>
                  <li>{tPrivacy("texto_privacy.ultimo")}</li>
                </ul>
                <p>
                  {tPrivacy("texto_privacy.relacion")}{" "}
                  <a href="https://amcargo.aeromexico.com/">
                    amcargo.aeromexico.com.
                  </a>{" "}
                  {tPrivacy("texto_privacy.haber")}
                </p>

                <h3>{tPrivacy("texto_privacy.once")}</h3>
                <p>{tPrivacy("texto_privacy.pa_once")}</p>
                <p>
                  {tPrivacy("texto_privacy.mayor")}{" "}
                  <a href="https://home.inai.org.mx/">www.inai.org.mx</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Privacy;
