/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.css";

import amc_logo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
// import {
//   listenToEvent,
//   stopListeningToEvent,
// } from "../../../../../services/socket";
import axios from "axios";

const Reception = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [turns, setTurns] = useState([]);
  const [callTurns, setCallTurns] = useState([]);

  const fetchAllShifts = async () => {
    try {
      // Ejecutar todas las solicitudes en paralelo
      const [currentRes, nextRes, lastRes] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/supervisor/turn_in_progress`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/supervisor/turns_next`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/supervisor/turns_in_progress`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        ),
      ]);

      // Procesar las respuestas
      let currentTurn = [];
      let nextTurns = [];
      let lastTurns = [];

      // Procesar turno actual
      if (currentRes.data.code === 1) {
        currentTurn = [{ ...currentRes.data.turn, type: "current" }];
      }

      // Procesar turnos siguientes
      nextTurns = (nextRes.data?.turns_next || []).map((turn) => ({
        ...turn,
        type: "last",
      }));

      // Procesar turnos pasados
      lastTurns = (lastRes.data?.data?.turns || []).map((turn) => ({
        ...turn,
        type: "next",
      }));

      // Ordenar por fecha
      nextTurns.sort((a, b) => new Date(a.date) - new Date(b.date));
      lastTurns.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Seleccionar los primeros 5 de 'next'
      const selectedNext = nextTurns.slice(0, 5);

      // Seleccionar los primeros 5 de 'last'
      const selectedLast = lastTurns.slice(0, 5);

      // Combinar en el orden solicitado
      const orderedShifts = [
        ...selectedLast,
        ...(currentTurn.length > 0 ? currentTurn : []),
        ...selectedNext,
      ];

      // Asignar al estado
      setTurns(orderedShifts);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  const getTurnsVoice = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn_scream`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        const newTurns = res.data.data.turns;
        if (callTurns.length === 0 || !arraysAreEqual(callTurns, newTurns)) {
          setCallTurns(newTurns);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deletedTurnsVoice = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn_scream/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleTextToSpeech = async (text) => {
    if (!text.trim()) {
      alert("Por favor, ingresa texto para convertirlo en audio.");
      return;
    }

    const speakText = () => {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "es-US";
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find((voice) => voice.lang === "es-US");

      // Verificar si se encontró la voz
      if (utterance.voice) {
        window.speechSynthesis.speak(utterance);
      } else {
        console.error("Intentando cargar las voces nuevamente...");
        // Reintentar después de un breve tiempo si la voz no está disponible
        setTimeout(speakText, 500);
      }
    };

    // Asegurarse de que las voces estén disponibles antes de ejecutar
    if (speechSynthesis.getVoices().length === 0) {
      speechSynthesis.onvoiceschanged = speakText;
    } else {
      speakText();
    }
  };

  useEffect(() => {
    fetchAllShifts();

    const interval = setInterval(fetchAllShifts, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(getTurnsVoice, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   // Escuchar el evento 'callTurn'
  //   listenToEvent("callTurn", (data) => {
  //     console.info("Llamar nuevo turno");
  //     getTurnsVoice();
  //   });

  //   return () => {
  //     stopListeningToEvent("callTurn");
  //   };
  // }, []);

  useEffect(() => {
    const processTurns = async () => {
      for (const turn of callTurns) {
        // Generar el texto para texto a voz
        const number = parseInt(turn.turn_number, 10).toString();
        const message = `Turno ${number} por favor dirigirse a ${turn.counter_checkout_name}`;

        // Esperar a que se complete el texto a voz
        handleTextToSpeech(message);

        // Llamar a la función delete y esperar a que retorne true
        const deleteSuccess = await deletedTurnsVoice(turn.id);
        if (!deleteSuccess) {
          console.error(`Error al eliminar el turno con ID: ${turn.id}`);
        }
      }
    };

    if (callTurns.length > 0) {
      processTurns();
    }
  }, [callTurns]);

  return (
    <>
      <div className="reception-container">
        <div className="reception-banner">
          <div className="banner-header">
            <div className="header-image">
              <img src={amc_logo} alt="amc-logo" />
            </div>
            <div className="header-hours">
              <span>{currentDate}</span>
              <span>{currentTime}</span>
            </div>
          </div>
          <div className="banner-body">
            <video
              src="https://dyn-pwc-ac.amlab7.com/pantalla-turnos-video-v2.mp4"
              autoPlay
              loop
              muted
              playsInline
              className="banner-video"
            />
          </div>
        </div>
        <div className="reception-turns">
          <div className="turns-body">
            <div className="next-shifts">
              <div className="tabla">
                <div className="tabla-header">
                  <div className="header-cell">Turno</div>
                  <div className="header-cell">Mostrador</div>
                  <div className="header-cell">Hora</div>
                </div>
                {turns.length === 0 ? (
                  <p
                    style={{
                      marginTop: "10vh",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    No hay turnos por mostrar
                  </p>
                ) : (
                  <>
                    {turns.slice(0, 11).map((item, index) => {
                      const checkoutName = item.counter_checkout_name
                        ? item.counter_checkout_name.replace("Mostrador ", "")
                        : "-";

                      return (
                        <div className="tabla-row" key={index}>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {item.turn_number}
                          </div>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {checkoutName}
                          </div>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {formatHour(item.date) === "Fecha inválida"
                              ? "-"
                              : formatHour(item.date)}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="turns-body-messages">
              <h1>ORDEN DE TURNO CONFORME A INSPECCIÓN</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reception;
