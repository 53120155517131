import axios from "axios";
import "./counters.css";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { HiOutlineX } from "react-icons/hi";
import { useForm } from "react-hook-form";

function Counters() {
  const [counters, setCounters] = useState([]);
  const [statusModal, setStatusModal] = useState({ status: false, modal: "" });
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      status: "1",
    },
  });

  useEffect(() => {
    getCounters();
  }, []);

  useEffect(() => {
    if (statusModal.status === false) {
      reset({
        name: "",
        status: "1",
      });
    }
  }, [statusModal]);

  const getCounters = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/counter`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCounters(response.data.data.counter_checkouts);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al consultar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  };

  const deleteCounter = async (id) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/counter/${id}`;
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "esta estacion sera eliminada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });
      if (result.isConfirmed) {
        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        Swal.fire({
          title: "¡Eliminado!",
          text: "Mostrador eliminado correctamente",
          icon: "success",
        });
        await getCounters();
      } else {
        Swal.fire("Cancelado", "No se realizo ninguna acción", "error");
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al eliminar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  };

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const updateCounter = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/counter/${data.id}`;
      const body = {
        name: data.name,
        status: data.status,
      };
      await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        title: "Exito",
        text: "Datos actualizados correctamente",
        icon: "success",
      });
      setStatusModal({ status: false, modal: "" });
      await getCounters();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al editar, por favor intentalo mas tarde",
        icon: "error",
      });
    }
  };

  const createCounter = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/counter`;
      const body = {
        name: data.name,
        status: data.status,
      };
      await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        title: "Exito",
        text: "Mostrador creado correctamente",
        icon: "success",
      });
      setStatusModal({ status: false, modal: "" });
      await getCounters();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al crear, por favor intentalo mas tarde",
        icon: "error",
      });
    }
  };

  const handleOpenForm = async (counter) => {
    setStatusModal({ status: true, modal: "edit" });
    reset({
      id: counter.id,
      name: counter.name,
      status: counter.status,
    });
  };

  const renderModalForm = () => (
    <>
      <form
        className="form-counter-update"
        onSubmit={handleSubmit(
          statusModal.modal === "create" ? createCounter : updateCounter
        )}
      >
        <div className="input-container-counter">
          <label>nombre:</label>
          <input
            id="name"
            type="text"
            placeholder=""
            {...register("name", { required: true })}
            autoComplete="off"
          />
        </div>
        <div className="alert-counter">
          {errors.name?.type === "required" && <p>El campo es requerido</p>}
        </div>

        <div className="input-container-counter">
          <label>status:</label>
          <select id="status" {...register("status")}>
            <option value={1}>Activo</option>
            <option value={2}>Inactivo</option>
          </select>
        </div>

        <input
          id="idCounter"
          style={{ display: "none" }}
          type="text"
          placeholder=""
          {...register("idCounter")}
          autoComplete="off"
        />

        <div className="btns-container">
          <button
            className="cancel-btn"
            onClick={() => setStatusModal({ status: false, modal: "" })}
          >
            Cancelar
          </button>
          <button className="send-btn" type="submit">
            Enviar
          </button>
        </div>
      </form>
    </>
  );

  return (
    <>
      <div className="counter-container">
        <div className="create-counter-container">
          <button
            className="btnn"
            onClick={() => setStatusModal({ status: true, modal: "create" })}
          >
            Crear Mostrador
          </button>
        </div>

        <div className="table-responsive">
          <table className="table table-preve">
            <thead>
              <tr>
                <th>Mostrador</th>
                <th>Status</th>
                <th>Fecha de creacion</th>
                <th>Acciones</th>
              </tr>
            </thead>

            <tbody>
              {counters.map((counter, index) => (
                <tr key={index}>
                  <td>{counter.name ? counter.name : ""}</td>
                  <td>{counter.status === "1" ? "Activo" : "Inactivo"}</td>
                  <td>{counter.created_at}</td>
                  <td className="btns-counter-container">
                    <button
                      className="btn-action-shift"
                      onClick={() => handleOpenForm(counter)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn-action-shift"
                      onClick={() => deleteCounter(counter.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {statusModal.status && (
        <div
          className="modal-window-content"
          onClick={() => setStatusModal({ status: false, modal: "" })}
        >
          <div className="modal-window" onClick={handleModalClick}>
            <div className="header-modal">
              <div></div>
              <div className="title">
                {statusModal.modal === "create"
                  ? "Crear Nuevo"
                  : "Editar Mostrador"}
              </div>
              <div
                className="close-btn"
                onClick={() => setStatusModal({ status: false, modal: "" })}
              >
                <HiOutlineX />
              </div>
            </div>
            {renderModalForm()}
          </div>
        </div>
      )}
    </>
  );
}

export default Counters;
