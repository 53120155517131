import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedDomesticRoute = ({ children }) => {
  const location = useLocation();

  // Verifica si la navegación proviene de /amc-domestico
  if (location.state && location.state.from === "/amc-domestico/light-load") {
    return children; // Renderiza el componente protegido
  }

  // Si no proviene de /amc-domestico, redirige
  return <Navigate to="/amc-domestico/light-load" />;
};

export default ProtectedDomesticRoute;
