import React, { useState, useEffect } from "react";
import "./weighing-control.css";
import logoAmc from "../../../../../assets/imgs/navbar/logo_AMC.svg";
import {
  listenToEvent,
  stopListeningToEvent,
} from "../../../../../services/socket";
import axios from "axios";

function WeighingControl() {
  const [shipments, setShipments] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/guard`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        setShipments(res.data.shipments);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Etiqueta emitida";
      case 2:
        return "Pesaje";
      case 3:
        return "Documentando";
      case 4:
        return "finalizado";
      default:
        return "";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "validated";
      case 2:
        return "in-process";
      case 3:
        return "documenting";
      case 4:
        return "approved";
      default:
        return "";
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // Escuchar el evento 'update'
    listenToEvent("Guard", (data) => {
      console.info("Nuevo Estatus");
      getData();
    });

    return () => {
      stopListeningToEvent("Guard");
    };
  }, []);

  return (
    <>
      <div className="header-weighing">
        <img className="logo-amc" src={logoAmc} alt="amc-logo" />
      </div>

      <div className="body-weighing">
        <h4>Inspección</h4>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th># Turno</th>
                <th># Embarque</th>
                <th>Hora emisión</th>
                <th># Guía</th>
                <th>Descripción</th>
                <th>Destino</th>
                <th>Piezas</th>
                <th>Pesaje</th>
              </tr>
            </thead>

            <tbody>
              {shipments.map((row, index) => (
                <tr key={index}>
                  <td>{row.turn_number ? row.turn_number : "-"}</td>
                  <td>{row.tag_number ? row.tag_number : "-"}</td>
                  <td>{row.created_at ? formatHour(row.created_at) : "-"}</td>
                  <td>{row.awb ? row.awb : "-"}</td>
                  <td>
                    {row.merchandise_description
                      ? row.merchandise_description
                      : "-"}
                  </td>
                  <td>{row.destination ? row.destination : "-"}</td>
                  <td>{row.pieces ? row.pieces : "-"}</td>
                  <td>
                    <span className={`status ${getStatusClass(row?.status)}`}>
                      {row.status ? getStatusText(row.status) : "-"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default WeighingControl;
