import { io } from "socket.io-client";

const socket = io(`${process.env.REACT_APP_URL_DOMESTIC_BACKEND_SOCKET}`, {
  transports: ["websocket"],
});

// Función para escuchar eventos
const listenToEvent = (eventName, callback) => {
  socket.on(eventName, callback);
};

// Función para dejar de escuchar eventos
const stopListeningToEvent = (eventName) => {
  socket.off(eventName);
};

// Función para emitir eventos desde el cliente
const emitEvent = (eventName, data) => {
  socket.emit(eventName, data);
};

export { listenToEvent, stopListeningToEvent, emitEvent };
