import React, { useState, useEffect } from "react";
import "./style.css";

import amc_logo from "../../assets/imgs/amc-domestico/logo_AMC.png";
import pink_lines from "../../assets/imgs/amc-domestico/Estelas_AMC.svg";

import { useNavigate } from "react-router-dom";

const Index = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [profile, setProfile] = useState("");
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const handleProfile = (e) => {
    setProfile(e.target.value);
  };

  const navigateToProfile = () => {
    if (profile) {
      const profileRoutes = {
        lightLoad: "/amc-domestico/light-load",
        heavyLoad: "/amc-domestico/heavy-load",
        collector: "/amc-domestico/",
      };

      navigate(profileRoutes[profile]);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="index-container">
        <div className="amc-domestico-index-header">
          <div className="amc-domestico-index-header-img">
            <div className="logo">
              <img src={amc_logo} alt="Logo" />
            </div>
            <div className="lines_pink">
              <img src={pink_lines} alt="lines" />
            </div>
          </div>
          <div className="amc-domestico-index-header-hours">
            <div className="hours">
              <span>{currentDate}</span>
              <span>{currentTime}</span>
            </div>
          </div>
        </div>
        <div className="amc-domestico-index-content">
          <div className="content-index">
            <div className="content-index-title">
              <h1>¡Bienvenido!</h1>
              <span>Selecciona el perfil que te corresponda</span>
            </div>
            <div className="content-index-type-profile">
              <div className="circles-container">
                {/* Carga Ligera */}
                <div className="index-profile-container">
                  <input
                    type="radio"
                    id="lightLoad"
                    name="profile"
                    value="lightLoad"
                    style={{ display: "none" }}
                    onChange={handleProfile}
                  />
                  <label
                    htmlFor="lightLoad"
                    className="content-index-circle"
                    style={
                      profile === "lightLoad"
                        ? { backgroundColor: "#035cf7" }
                        : {}
                    }
                  >
                    <svg
                      width="54"
                      height="52"
                      viewBox="0 0 54 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M41.7224 6.3537L36.4436 3.61053C31.8089 1.20438 29.4915 0 26.9999 0C24.5083 0 22.1909 1.20177 17.5561 3.61053L16.7089 4.05205L40.2602 17.3734L50.86 12.1222C49.155 10.2098 46.4047 8.78075 41.7224 6.34847M52.7287 15.5812L42.1764 20.8063V28.7379C42.1764 29.2576 41.9679 29.756 41.5966 30.1235C41.2254 30.4909 40.7219 30.6973 40.1969 30.6973C39.6719 30.6973 39.1684 30.4909 38.7971 30.1235C38.4259 29.756 38.2173 29.2576 38.2173 28.7379V22.7657L28.9794 27.3376V52C30.8745 51.5324 33.0309 50.4142 36.4436 48.6403L41.7224 45.8971C47.3998 42.9476 50.2398 41.4741 51.8181 38.8223C53.3938 36.1732 53.3938 32.8736 53.3938 26.2822V25.9765C53.3938 21.0309 53.3938 17.9377 52.7287 15.5812ZM25.0204 52V27.3376L1.27108 15.5812C0.605957 17.9377 0.605957 21.0309 0.605957 25.9713V26.2769C0.605957 32.8736 0.605957 36.1732 2.18167 38.8223C3.76003 41.4741 6.60002 42.9502 12.2774 45.8997L17.5561 48.6403C20.9689 50.4142 23.1253 51.5324 25.0204 52ZM3.13978 12.1248L26.9999 23.9335L36.0029 19.4791L12.5492 6.21262L12.2774 6.3537C7.59771 8.78336 4.84482 10.2124 3.13978 12.1274"
                        fill="white"
                      />
                    </svg>
                  </label>
                  <div className="content-index-label">
                    <span>Carga Ligera</span>
                  </div>
                </div>

                {/* Carga Pesada */}
                <div className="index-profile-container">
                  <input
                    type="radio"
                    id="heavyLoad"
                    name="profile"
                    value="heavyLoad"
                    style={{ display: "none" }}
                    onChange={handleProfile}
                  />
                  <label
                    htmlFor="heavyLoad"
                    className="content-index-circle"
                    style={
                      profile === "heavyLoad"
                        ? { backgroundColor: "#035cf7" }
                        : {}
                    }
                  >
                    <svg
                      width="59"
                      height="52"
                      viewBox="0 0 59 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.605957 52V11.5556L29.4948 0L58.3837 11.5556V52H41.0504V28.8889H17.9393V52H0.605957ZM20.8282 52V46.2222H26.606V52H20.8282ZM26.606 43.3333V37.5556H32.3837V43.3333H26.606ZM32.3837 52V46.2222H38.1615V52H32.3837Z"
                        fill="white"
                      />
                    </svg>
                  </label>
                  <div className="content-index-label">
                    <span>Carga Pesada</span>
                  </div>
                </div>

                {/* Recoleccion */}
                <div className="index-profile-container">
                  <input
                    type="radio"
                    id="collector"
                    name="profile"
                    value="collector"
                    style={{ display: "none" }}
                    onChange={handleProfile}
                  />
                  <label
                    htmlFor="collector"
                    className="content-index-circle"
                    style={
                      profile === "collector"
                        ? { backgroundColor: "#035cf7" }
                        : {}
                    }
                  >
                    <svg
                      width="66"
                      height="52"
                      viewBox="0 0 66 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M51.7935 35.7475H14.4591C14.0122 35.7475 13.6466 36.1131 13.6466 36.56L13.6364 41.435C13.6364 41.8818 14.0021 42.2475 14.4489 42.2475H51.7935C52.2403 42.2475 52.606 41.8818 52.606 41.435V36.56C52.606 36.1131 52.2403 35.7475 51.7935 35.7475ZM51.7935 45.4975H14.4286C13.9817 45.4975 13.6161 45.8631 13.6161 46.31L13.606 51.185C13.606 51.6318 13.9716 51.9975 14.4185 51.9975H51.7935C52.2403 51.9975 52.606 51.6318 52.606 51.185V46.31C52.606 45.8631 52.2403 45.4975 51.7935 45.4975ZM51.7935 25.9975H14.4794C14.0325 25.9975 13.6669 26.3631 13.6669 26.81L13.6567 31.685C13.6567 32.1318 14.0224 32.4975 14.4692 32.4975H51.7935C52.2403 32.4975 52.606 32.1318 52.606 31.685V26.81C52.606 26.3631 52.2403 25.9975 51.7935 25.9975ZM62.6099 11.8803L34.9747 0.373249C34.3807 0.126837 33.744 0 33.1009 0C32.4578 0 31.821 0.126837 31.2271 0.373249L3.60205 11.8803C1.79424 12.642 0.605957 14.4092 0.605957 16.3795V51.185C0.605957 51.6318 0.971582 51.9975 1.41846 51.9975H9.54346C9.99033 51.9975 10.356 51.6318 10.356 51.185V25.9975C10.356 24.21 11.8388 22.7475 13.6669 22.7475H52.545C54.3731 22.7475 55.856 24.21 55.856 25.9975V51.185C55.856 51.6318 56.2216 51.9975 56.6685 51.9975H64.7935C65.2403 51.9975 65.606 51.6318 65.606 51.185V16.3795C65.606 14.4092 64.4177 12.642 62.6099 11.8803Z"
                        fill="white"
                      />
                    </svg>
                  </label>
                  <div className="content-index-label">
                    <span>Recolección</span>
                  </div>
                </div>
              </div>
            </div>
            {showError && (
              <span className="alert-text">Debe selecionar un perfil</span>
            )}

            <div className="content-index-buton">
              <button
                type="button"
                className="content-index-buton-next"
                onClick={navigateToProfile}
              >
                {" "}
                Iniciar &#x276f;
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
