import React, { useState, useEffect } from "react";
import "./style.css";

import amc_logo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import pink_lines from "../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { IoMdExit } from "react-icons/io";

const Header = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const userName = localStorage.getItem("light-load-user");
  const routes = [
    "/amc-domestico/light-load",
    "/amc-domestico/light-load/clients",
  ];

  const isLogin = routes.some((ruta) =>
    new RegExp(`^${ruta}/?$`).test(location.pathname)
  );

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/amc-domestico/light-load/");
  };

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="amc-domestic-header">
        <div className="amc-domestic-header-img">
          <div className="logo">
            <img src={amc_logo} alt="Logo" />
          </div>
          <div className="lines_pink">
            <img src={pink_lines} alt="lines" />
          </div>
        </div>
        <div className="amc-domestic-header-hours">
          <div className="hours" style={isLogin ? { width: "100%" } : {}}>
            <span>{currentDate}</span>
            <span>{currentTime}</span>
          </div>
          {isLogin ? (
            ""
          ) : (
            <div className="user">
              <div className="username">
                <span>Usuario: {userName}</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenDropdown}
                >
                  ▼
                </span>
              </div>
              {openDropdown && (
                <div className="dropdownMenuHeader">
                  <ul>
                    <li>
                      <button
                        type="button"
                        className="exit-btn"
                        onClick={logOut}
                      >
                        Cerrar sesion <IoMdExit />
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
