import { useForm } from "react-hook-form";
import "./login-reports.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function LoginReports() {
  const [reportsArr, setReportsArr] = useState([]);
  const [notFoundData, setNotFoundData] = useState(false);
  const { handleSubmit, register, reset, setValue, formState: {errors, isValid} } = useForm();

  const handleSearchReports = async (data) => {
    try {
      const finishDate = await formatDate(data.endDate);
      const startDate = await formatDate(data.startDate);
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/kpis/login?user=${data.user}&finishDate=${finishDate}&startDate=${startDate}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if(response.data.operations.length === 0) {
        setNotFoundData(true);
        setReportsArr([]);
      } else {
        setNotFoundData(false);
        setReportsArr(response.data.operations);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error al consultar, por favor intentalo de nuevo",
        icon: "error"
      });
    }
  }

  const formatDate = async (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  const handleModuleType = (type) => {
    if(type === 1) {
      return "Carga ligera";
    } else {
      return "Carga pesada";
    }
  }

  const getProfile = (profile) => {
    switch (profile) {
      case 1:
        return "Cliente"
        break;
      case 2:
        return "Pesador"
        break;
      case 3:
        return "Documentador"
        break;
      case 4:
        return "Pantalla turnos"
        break;
      case 5:
        return "Inspector"
        break;
      default:
        return "";
        break;
    }
  }

  const renderNotData = () => (
    <p className="text-not-found-data">No se encontraron resultados!</p>
  );

  return (
    <>
      <div className="login-reports-container">
        <form
          className="login-reports-form"
          onSubmit={handleSubmit(handleSearchReports)}
        >
          <div className="formGroup">
            <input
              style={{minWidth: '270px'}}
              id="user"
              type="text"
              placeholder=" "
              className="formInput"
              {...register("user", {
                required: true,
              })}
            />
            <label htmlFor="user" className="formLabel">
              User
            </label>
            <div className="alertTrackForm">
              {errors.user?.type === "required" && (
                <p>El campo es requerido</p>
              )}
            </div>
          </div>

          <div className="formGroup">
            <input
              id="startDate"
              type="date"
              placeholder=" "
              className="formInput"
              {...register("startDate", {
                required: true
              })}
            />
            <label>Fecha inicial</label>
            <div className="alertTrackForm">
              {errors.startDate?.type === "required" && (
                <p>El campo es requerido</p>
              )}
            </div>
          </div>

          <div className="formGroup">
            <input
              id="endDate"
              type="date"
              placeholder=" "
              className="formInput"
              {...register("endDate", {
                required: true,
              })}
            />
            <label>Fecha Final</label>
            <div className="alertTrackForm">
              {errors.startDate?.type === "required" && (
                <p>El campo es requerido</p>
              )}
            </div>
          </div> 

          <button className='btnn' type="submit">Buscar</button>

        </form>

        <div className='table-responsive'>
          <table className='table table-preve' id='tableToSearch'>
            <thead>
              <tr>
                <th>Descripción</th>
                <th>Módulo</th>
                <th>Perfil</th>
                <th>Fecha</th>
                <th>Hora</th>
              </tr>
            </thead>

            <tbody>
              {reportsArr.map((report, index) => (
                <tr key={index}>
                  <td>{report.description}</td>
                  <td>{report.module_type ? handleModuleType(report.module_type) : ""}</td>
                  <td>{report.profile ? getProfile(report.profile) : ""}</td>
                  <td>{report.created_at.split(" ")[0]}</td>
                  <td>{report.created_at.split(" ")[1]}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>

        {notFoundData && renderNotData()}
      </div>
    </>
  )
};

export default LoginReports;