import axios from "axios";
import "./shift-change.css";
import { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function ShiftChange() {
  const [currentTime, setCurrentTime] = useState("");
  const [accordionStatus, setStatusAccordion] = useState(false);
  const [timeText, setTimeText] = useState("");
  const { handleSubmit, register, reset, setValue, formState: {errors, isValid} } = useForm();

  useEffect(() => {
    getTime();
  }, []);

  const getTime = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/time/set_timeout`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    });

    const [minuts, seconds] = response.data.data.setTimeout.split(":");
    setCurrentTime(response.data.data.setTimeout);

    if(minuts !== "00") {
      setTimeText("minutos")
    } else {
      setTimeText("segundos")
    }
  }

  const handleAccordionStatus = () => {
    if(accordionStatus === false) {reset()}
    setStatusAccordion(!accordionStatus);
  }

  const handleChageTime = async (data) => {
    const newTime = {"time": data.time}
    try {
      await axios.post(`${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/time/set_timeout`, newTime, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      reset();
      Swal.fire({
        title: "Exito",
        text: "Tiempo cambiado exitosamente",
        icon: "success"
      });
      await getTime();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al cambiar el tiempo.",
        icon: "error"
      });
    }
  }

  return (
    <>
      <div className="shift-change-container">
        <div className="current-time">
          <h5 className="text-current">Tiempo actual:</h5>
          <h5 className="time">{currentTime}</h5>
          <h5 className="text">{timeText}</h5>
        </div>

        <div className="change-time-container">
          <div className="header-time" onClick={handleAccordionStatus}>
            <p>Cambiar Tiempo</p>
            <span className={`down-arrow ${accordionStatus ? "up" : "down"}`}><FaCaretDown /></span>
          </div>
          <div className={`body-time ${accordionStatus ? "active" : "false"}`}>

            <p className="instructions">Para cambiar el tiempo es necesario utilizar el siguiente formato: "MM:SS"</p>
            <p>ejemplo: 01:10 en este ejemplo seria: 1 Minuto 10 Segundos</p>

            <form onSubmit={handleSubmit(handleChageTime)} className="change-time-form" autoComplete="off">
              <div className="col-12 formGroup">
                <input
                  id="time"
                  type="text"
                  placeholder=" "
                  className="formInput"
                  {...register("time", {
                    required: true,
                    validate: {
                      onlyNumbers: (value) => /^[0-9:]*$/.test(value) || "Solo se permiten números y ':'",
                      validFormat: (value) => {
                        if (!/^\d{2}:\d{2}$/.test(value)) {
                          return "Formato inválido. Debe ser 00:00";
                        }
                        const [hours, minutes] = value.split(":").map(Number);
                        if (hours > 60) {
                          return "La hora no puede ser mayor a 60";
                        }
                        if (minutes > 59) {
                          return "Los minutos no pueden ser mayores a 59";
                        }
                        return true;
                      },
                    },
                  })}
                />
                <label htmlFor="time" className="formLabel">
                  00:00
                </label>
                <div className="alertTrackForm">
                  {errors.time?.type === "required" && (
                    <p>El campo es requerido</p>
                  )}
                  {errors.time?.message && (
                    <p>{errors.time?.message}</p>
                  )}
                </div>
              </div>

              <button type="submit" className="btn-blue">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShiftChange;